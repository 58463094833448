import React, {useEffect, useState} from 'react'
import {OrderBoxWrap} from './styles'
import Button from '../../module/button'
import BorderInput from '../inputBox/BorderInput'
import Select from '../../module/select'
import {OrderAction} from '../../store/action'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {authAtom} from '../../store/recoil/auth'
import {useLocation, useNavigate} from 'react-router'
import {alertAtom} from '../../store/recoil/alert'
import MainPay from "../../module/mainPay";
import {OrderPriceAction} from '../../store/action/orderPrice'
import CallMainPay from "../../module/mainPay/callMainPay";
import wingPay from "../../module/wingPay";
import {orderAtom} from "../../store/recoil/order";

const OrderBox = () => {

  // 로그인 한 유저
  const auth = useRecoilValue(authAtom)

  const [amount, setAmount] = useState(0)

  // Location
  const location = useLocation()

  // Navigate
  const navigate = useNavigate()

  // Alert
  const setAlert = useSetRecoilState(alertAtom)

  // Order Action
  const {order} = OrderAction()
  const {getOrderPirceTitle} = OrderPriceAction()

  // 결제할 상품 옵션
  const options = location?.state?.shopOptions

  // 결제 상품 정보
  const shopDetail = location?.state?.shopDetail

  // 결제 정보들
  const [orderInfo, setOrderInfo] = useRecoilState(orderAtom)

  // 쿠폰 받을 휴대전화
  const [selectValue, setSelectValue] = useState('010')
  const [phone, setPhone] = useState('')
  const optionList = [
    {
      value: '010',
      label: '010'
    },
    {
      value: '011',
      label: '011'
    },
    {
      value: '016',
      label: '016'
    },
    {
      value: '019',
      label: '019'
    }
  ]
  const handleSelectChange = (v: string) => {
    setSelectValue(v)
  }


  // 주문 결제 시 상품 이름
  const [orderProductName, setOrderProductName] = useState('')


  function openCenteredWindow(url: string, width: number, height: number) {
    // 현재 브라우저 창의 너비와 높이를 가져옵니다.
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // 새 창의 위치를 계산합니다.
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    return
  }

// 사용 예
  const handleSubmit = () => {
    const user = { name: auth.name, email: auth.email, phone: auth.phone, birth: auth.birth };
    const product = orderInfo.options.filter(it => it.cnt > 0).map((item: any) => {
      return {
        prodId: item.id,
        name: orderProductName,
        qty: item.cnt,
        price: (item.original_price + item.tax) * item.cnt,
        desc: ''
      };
    });

    const newWindow = window.open(
      'http://korpay.storyapp.co.kr/paySample.jsp',
      '_blank',
      'width=1050, height=700, popup=true, left=200, top=200, menubar=no, location=no, status=no, scrollbars=no'
    );

    setTimeout(() => {
      if (newWindow) {
        newWindow.postMessage({
          product_name: product[0].name,
          price: product[0].price,
          buyer: user.name,
          email: user.email,
          phone: user.phone,
          orderNo: `story_pay_${new Date().getTime()}`,
        }, 'http://korpay.storyapp.co.kr/');
      }
    }, 1000); // Adjust the delay as necessary
  };

  const receiveMessage = (event: any, info: any) => {
    // 이벤트 객체에서 메시지를 가져옵니다.
    if(event && event.data){
      const message: any = JSON.parse(event.data);
      let orderInfoTemp: any = {}
      console.log(auth)
      if(message){
        if(message.result.content === "[3001]정상 처리 되었습니다."){
          getOrderPirceTitle(totalPrice)
            .then((res) => {
              orderInfoTemp = {
                ...orderInfo,
                orderName: res.payload,
                user_id: auth?.id,
                total: totalPrice,
                pay_method: "카드결제",
                totalCnt: totalCnt,
                receiver_name: auth?.name,
                //receiver_phone: selectValue + phone,
                receiver_phone: auth?.phone,
                options: options
              }

              const result = {
                service_id: message.result.transactionId,
                order_id: message.result.transactionId,
                track_id: message.result.transactionId,
                amount: message.result.amt,
                trxdate: new Date().getTime(),
                authcd: "",
                response_code: message.result.content,
              }
              order({
                ...orderInfoTemp,
                ...result
              }).then(() => {
                navigate('/my/order')
              })
            })
        }
      }
    }
  };


  // 총 주문 금액 / 카운트
  const totalPrice = options?.reduce((acc: any, cur: any) => acc + ((cur.cnt || 0) * (cur.original_price + cur.tax)), 0)
  const totalCnt = options?.reduce((acc: any, cur: any) => acc + cur.cnt, 0)

  // 상품 정보 가져오기
  useEffect(() => {
    setAmount(totalPrice)
    console.log(options)
    getOrderPirceTitle(totalPrice)
      .then((res) => {
        setOrderProductName(res.payload)
        setOrderInfo({
          ...orderInfo,
          orderName: res.payload,
          user_id: auth?.id,
          total: totalPrice,
          pay_method: "카드결제",
          totalCnt: totalCnt,
          receiver_name: auth?.name,
          //receiver_phone: selectValue + phone,
          receiver_phone: auth?.phone,
          options: options
        })
      })

    setSelectValue(auth?.phone?.slice(0, 3))
    setPhone(auth?.phone?.slice(3, 11))
  }, [auth, options])

  // 상품 정보 없이 접근 시 home으로 보내기
  useEffect(() => {
    if (!options) {
      navigate('/')
      setAlert({
        title: '오류',
        type: 'alert',
        message: '정상적이지 않은 접근입니다.',
        isShow: true
      })
    }
  }, [auth, location])

    useEffect(() => {
      window.addEventListener('message', e => receiveMessage(e, orderInfo));

      // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
      return () => {
        window.removeEventListener('message', e => receiveMessage(e, orderInfo));

      };
    }, []); // 빈 배열을 두 번째 인수로 전달하여, 컴포넌트가 처음 마운트될 때 한 번만 실행되도록 합니다.


    return (
    <OrderBoxWrap>
      <div className="left-box">
        <div className="order-type">
          {/*<MainPay */}
          {/*  username={auth?.name} */}
          {/*  amount={totalPrice?.toString()} */}
          {/*  goodsname={orderProductName || shopDetail?.name}*/}
          {/*/>*/}
          <Button
            text='신용카드결제'
            borderColor='#d9d9d9'
            textColor='rgba(0, 0, 0, 0.87)'
            backgroundColor='#d9d9d9'
            icon='/image/card-icon.png'
            boxShadow='0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
          />
        </div>
        <div className="coupon-wrap">
          <h4>쿠폰을 받을 휴대폰 번호</h4>
          <div className="phone-wrap">
            <Select
              optionList={optionList}
              value={selectValue}
              handleChange={handleSelectChange}
              borderType={true}
              disabled={true}
            />
            <BorderInput
              title='-없이 숫자만 입력'
              closeNone={true}
              value={phone}
              handleChange={(v) => setPhone(v)}
              disabled={true}
            />
          </div>
          <div className="info-text">
            <span>* 입력한 번호로 쿠폰 묶음이 전송됩니다. 정확하게 입력해주세요.</span>
            <span>* 휴대폰 결제는 입력한 번호로만 결제할 수 있습니다.</span>
          </div>
        </div>
      </div>
      <div className="right-box">
        <div className="price-wrap">
          <dl>
            <dt>총 상품가격</dt>
            <dd>{Number(totalPrice).toLocaleString('ko-KR')} 원</dd>
          </dl>
          <dl>
            <dt>총 결제 예상 금액</dt>
            <dd>{Number(totalPrice).toLocaleString('ko-KR')} 원</dd>
          </dl>
        </div>
        <Button
          text='결제하기'
          borderColor='#28CBEB'
          textColor='#fff'
          backgroundColor='#28CBEB'
          handleClick={handleSubmit}
        />
      </div>
    </OrderBoxWrap>
  )
}

export default OrderBox
