import styled from "styled-components";

export const SliderWrap = styled.div`
  position: relative;
  .arrow-wrap {
    display: flex;
    align-items: center;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    top: -8rem;
    button {
      width: 5rem;
      height: 5rem;
      &.slider-prev {
        background: url('/image/prev-icon.png') no-repeat center #fff;
      }
      &.slider-next {
        background: url('/image/next-icon.png') no-repeat center #fff;
      }
    }
  }
  .item {
    display: block;
    border: 0.1rem solid #dfdfdf;
    border-radius: 1.5rem;
    overflow: hidden;
    transition: all ease 0.3s;
    &:hover {
      border-color: #28CBEB;
    }
    .figure {
      width: 100%;
      padding-top: 100%;
    }
    .info {
      padding: 2rem 1.5rem;
      .title {
        font-size: 2rem;
        font-weight: 500;
      }
      .text{
        font-size: 1.6rem;
        color: #28CBEB;
        margin-top: 1rem;
      }
    }
  }
  .empty {
    width: 100%;
    height: auto;
    padding: 14rem 0;
    text-align: center;
    font-size: 1.6rem;
    border: 0.1rem solid #dfdfdf;
    border-radius: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    .arrow-wrap {
      display: none;
    }
  } 
  @media screen and (max-width: 500px) {
    .item {
      .info {
        padding: 1.5rem;
        .title {
          font-size: 1.6rem;
        }
        .text {
          font-size: 1.4rem;
        }
      }
    }
  } 
`
