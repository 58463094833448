import styled from "styled-components";

export const JoinWrap = styled.div`
  padding: 11rem 0;
  .join-wrap {
    padding-top: 3rem;
    border-top: 0.1rem solid #dfdfdf;
  }
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
  }
  @media screen and (max-width: 768px) {
    padding: 6rem 0;
  }
`

export const TitleWrap = styled.div`
  margin-bottom: 3rem;
  h2 {
    font-size: 3.6rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 3.1rem;
    }
  }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 2.6rem;
    }
  }
`

export const SubTitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  .num {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: rgba(0,0,0,.38);
    text-align: center;
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: #fff;
    margin-right: 1.2rem;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 400;
    color: rgba(0,0,0,.38);
  }
  &.active {
    .num {
      background-color: #28CBEB;
    }
    h3 {
      color: #222;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 1.5rem;
    .num {
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      line-height: 2rem;
      margin-right: 1rem;
    }
    h3 {
      font-size: 1.4rem;
    }
  }
`

export const Step01Wrap = styled.div`
  padding: 1.6rem 3rem;
  border-left: 0.1rem solid #dfdfdf;
  overflow: hidden;
  transition: all ease 0.5s;
  margin-left: 1.2rem;
  margin-bottom: 1.6rem;
  &.active {
    .all-agree {
      display: flex;
    }
  }
  .all-agree {
    width: 100%;
    height: auto;
    border: 0.1rem solid #dfdfdf;
    border-radius: 0.4rem;
    padding: 1.6rem;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    h4 {
      font-size: 1.6rem;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .policy-wrap {
    margin-top: 3rem;
    .text-wrap {
      border-top: 0.2rem solid #28CBEB;
      border-bottom: 0.1rem solid #dfdfdf;
      height: 40rem;
      overflow-y: auto;
      padding: 1.6rem;
    }
  }
  .btn-wrap {
    margin-top: 3rem;
  }
  @media screen and (max-width: 500px) {
    padding: 1.5rem;
    padding-right: 0;
    .all-agree {
      padding: 1.2rem;
    }
    .title-wrap {
      h4 {
        font-size: 1.4rem;
      }
    }
    .policy-wrap {
      .text-wrap {
        height: 25rem;
        padding: 1rem;
        .contents {
          font-size: 1.4rem;
        }
      }
    }
  }
`

export const Form = styled.div`

`

export const Step02Wrap = styled.div`
  padding: 1.6rem 3rem;
  border-left: 0.1rem solid #dfdfdf;
  overflow: hidden;
  transition: all ease 0.5s;
  margin-left: 1.2rem;
  margin-bottom: 1.6rem;
  height: 0;
  &.active {
    height: 77.4rem;
    .info-box {
      display: flex;
    }
  }
  .info-box {
    display: none;
    align-items: center;
    gap: 2rem;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
    .line {
      display: inline-block;
      font-size: 2.4rem;
      color: rgba(0,0,0,.54);
      margin-top: 0.7rem;
    }
    .email {
      display: inline-block;
      font-size: 1.6rem;
      color: rgba(0,0,0,.54);
      margin-top: 1rem;
    }
  }
  .w50 {
    width: 50%;
  }
  .w75 {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    &.active {
      height: 85.8rem;
      .info-box {
        flex-wrap: wrap;
        &.mo-db {
          display: block;
          > label {
            &:not(:first-child) {
              margin-top: 3rem;
            }
          }
        }
      }
    }
    .w50, .w75 {
      width: 100%;
    }
    .info-box {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      &.email {
        > label {
          &:last-of-type {
            width: 100%;
            flex: unset !important;
            margin-top: 0;
            select {
              padding: 0;
            }
            span {
              right: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 1.5rem;
    padding-right: 0;
    &.active {
      height: 76.2rem;
    }
    .info-box {
      gap: 1.5rem;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      .email {
        font-size: 1.4rem;
      }
      .line {
        font-size: 1.6rem;
      }
    }
  }
`

export const Step03Wrap = styled.div`
  padding: 1.6rem 3rem;
  overflow: hidden;
  transition: all ease 0.5s;
  margin-left: 1.2rem;
  margin-bottom: 1.6rem;
  height: 0;
  &.active {
    height: 30.4rem;
    .box {
      display: block;
    }
  }
  .box {
    display: none;
    padding: 3.2rem;
    background-color: #f5f5f5;
    .circle {
      width: 6rem;
      height: 6rem;
      border: 0.5rem solid #28CBEB;
      border-radius: 50%;
      padding: 0.5rem;
      margin: 0 auto;
      i {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #faa12f;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 3rem;
          height: 3rem;
          background: url('/image/white-check-icon.png') no-repeat center / cover;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    h5 {
      font-size: 2.4rem;
      font-weight: 500;
      text-align: center;
      margin-top: 2rem;
    }
    p {
      font-size: 1.6rem;
      font-weight: 400;
      text-align: center;
      margin-top: 1rem;
    }
    a {
      display: block;
      width: 16rem;
      height: 5rem;
      border-radius: 0.4rem;
      font-size: 1.6rem;
      line-height: 4.8rem;
      font-weight: 500;
      color: #28CBEB;
      border: 0.1rem solid #28CBEB;
      text-align: center;
      margin: 0 auto;
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0;
    margin: 0;
    &.active {
      height: 23.6rem;
    }
    .box {
      padding: 3rem 1.5rem;
      .circle {
        width: 5rem;
        height: 5rem;
      }
      h5 {
        font-size: 2rem;
        margin-top: 1.5rem;
      }
      p {
        font-size: 1.4rem;
      }
      a {
        height: 4rem;
        font-size: 1.4rem;
        line-height: 4rem;
      }
    }
  }
`
