import React, { useEffect, useState } from 'react'
import { ShopDetailWrap } from './styles'
import Button from '../../module/button'
import Count from '../../components/count'
import LineTab from '../../components/tab/LineTab'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authAtom } from '../../store/recoil/auth'
import { useLocation, useNavigate } from 'react-router'
import LoginModal from '../../components/loginModal'
import { alertAtom } from '../../store/recoil/alert'
import { GuideAction } from '../../store/action/guide'
import { GuideInterface, ShopDetailInterface, ShopOptionInterface } from './types'
import { UserProductAction } from '../../store/action/userProduct'

const ShopDetail = () => {

  // location
  const location = useLocation()

  // 로그인 한 유저 정보
  const auth = useRecoilValue(authAtom)

  const setAlert = useSetRecoilState(alertAtom)

  // navigate
  const navigate = useNavigate()

  const [increaseFlag, setIncreaseFlag] = useState(true)

  // 텝메뉴
  const [tabActive, setTabActive] = useState(0)
  const handleChangeTab = (i: number) => {
    setTabActive(i)
  }

  // 로그인 모달
  const [loginModal, setLoginModal] = useState(false)
  const handleModal = (boolean: boolean) => {
    setLoginModal(boolean)
  }


  // 구매정보
  const [guide, setGuide] = useState<GuideInterface[]>([])

  const {getGuideList} = GuideAction()

  useEffect(() => {
    getGuideList()
    .then((res) => {
      setGuide(res)
    })
  }, [])

  // 상품 상세 정보
  const [shopDetail, setShopDetail] = useState<ShopDetailInterface>({
    id: 0,
    categories: '',
    images: '',
    thumbnail: '',
    code: '',
    name: '',
    display_order: 0,
    display: 0,
    soldout: 0,
    content: '',
    updated_at: '',
    created_at: '',
    grade: 0
  })
  const [shopOptions, setShopOptions] = useState<ShopOptionInterface[]>([])

  // 카운트 가져오기
  const handleChangeCount = (index: number, updatedCount: number) => {
    setShopOptions(prevCount => {
      const newCount = [...prevCount]

      const prevPrice = totalPrice - (newCount[index].original_price + newCount[index].tax) * newCount[index].cnt
      const plusPrice = (newCount[index].original_price + newCount[index].tax) * updatedCount

      if(prevPrice + plusPrice > 2970000){
        setIncreaseFlag(false)
        return newCount
      }

      setIncreaseFlag(true)
      newCount[index].cnt = updatedCount
      return newCount
    });
  }

  // Product Action
  const { getProductDetail } = UserProductAction()

  // 상품 상세 가져오기
  useEffect(() => {
    const shopId = location.pathname.split('/')[3]
    getProductDetail(Number(shopId))
    .then((res) => {
      setShopDetail(res.product[0])
      const addOptionsCnt = res.options.map((option: any) => ({
        ...option,
        cnt: 0
      }))
      setShopOptions(addOptionsCnt)
    })
  }, [location])

  // 총 합계 금액
  const [totalPrice, setTotalPrice] = useState(0)

  // 카운트 + 합계
  useEffect(() => {
    const price = shopOptions.reduce((acc, cur) => acc + ((cur.cnt || 0) * (cur.original_price + cur.tax)), 0)

    setTotalPrice(price)
  }, [shopOptions])

  // 윈도우 넓이
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // 구매하기
  const handleBuy = () => {
    if (auth.order_limit === 1 || (auth.member_grade < shopDetail.grade)) {
      setAlert({
        title: '상품 구매',
        type: 'alert',
        message: '품절된 상품입니다.',
        isShow: true
      })
    } else if (!shopOptions.some((value) => value.cnt > 0)) {
      setAlert({
        title: '상품 구매',
        type: 'alert',
        message: '구매 수량을 선택해주세요.',
        isShow: true
      })
    } else if (!auth.token) {
      windowWidth > 768 ? setLoginModal(true) : navigate('/login')
    } else {
      navigate('/order', {state: {
        shopOptions: shopOptions,
        shopDetail: shopDetail
      }})
    }
  };

  const shopImage = shopDetail?.images && JSON.parse(shopDetail.images)[0].path


  // auth.member_grade >= shopDetail?.grade 이래야 주문이 가능해야함
  // auth.order_limit 이거 1이면 무조건 구매 못함(품절 표시)

  // auth.order_limit || (auth.member_grade < shopDetail.grade) ? 품절처리 : 구매가능

  // 로그인 다시하면 회원정보에 둘다 내려온다.

  // {auth.member_grade} / {shopDetail?.grade}

  // {auth.order_limit}

  return (
    <ShopDetailWrap>
      <div className="container">
        <div className="shop-info">
          <div className="figure">
            <span style={{background: `url('${process.env.REACT_APP_BASE_URL}${shopImage}') no-repeat center / cover`}}></span>
          </div>
          <div className="mobile-title">{shopDetail?.name}</div>
          <div className="info-wrap">
            <div className="title">{shopDetail?.name}</div>
            <div className="option">
              {
                shopOptions?.length > 0 ? shopOptions.map((item, index) => {
                  return (
                    <dl key={index}>
                      <dt>
                        {item.name} ({
                          auth.order_limit === 0 && (auth.member_grade >= shopDetail.grade) ? `${item.totalCnt}개` : '품절'
                        })
                      </dt>
                      <dd>
                        <Count
                          value={shopOptions[index].cnt}
                          increaseFlag={increaseFlag}
                          maxCount={auth.order_limit === 1 || (auth.member_grade < shopDetail.grade) ? 0 : 
                            item.totalCnt}
                          handleChangeCount={(c) => handleChangeCount(index, c)}
                        />
                      </dd>
                    </dl>
                  )
                }) : (
                  <div className='empty'>등록된 옵션이 없습니다.</div>
                )
              }
            </div>
            <div className="total-price">
              <span>총 합계</span>
              <span>{totalPrice.toLocaleString('ko-KR')} 원</span>
            </div>
            <div className="btn-wrap">
              <Button
                text='구매하기'
                borderColor='#28CBEB'
                backgroundColor='#28CBEB'
                textColor='#fff'
                icon={'/image/shopping-bag-icon.png'}
                handleClick={handleBuy}
              />
            </div>
          </div>
        </div>
        <div className="tab-wrap">
          <LineTab
            tabList={['상품정보', '구매정보']}
            handleChange={handleChangeTab}
          />
        </div>
        {
          tabActive === 0 ? (
            <div className="contents" dangerouslySetInnerHTML={{ __html: shopDetail.content }} />
          ) : (
            <div className="policy-wrap">
              <h5>교환/반품/품절/안내</h5>
              <div className="info-wrap">
                <ul>
                  {
                    guide.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="title" dangerouslySetInnerHTML={{ __html: item.title }} />
                          <div className="text" dangerouslySetInnerHTML={{ __html: item.content }} />
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          )
        }
      </div>
      <LoginModal
        modalStatus={loginModal}
        handleModal={handleModal}
      />
    </ShopDetailWrap>
  )
}

export default ShopDetail
