import styled from "styled-components";

export const InputBoxWrap = styled.label`
  flex: 1;
  display: block;
  position: relative;
  span {
    display: block;
    min-height: 1.4rem;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.6);
    transition: all ease 0.3s;
    &.focus {
      color: #28CBEB;
    }
    &.disabled {
      color: rgba(0,0,0,.38);
    }
  }
  .input-wrap {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 0rem;
      height: 0.2rem;
      background-color: #28CBEB;
      position: absolute;
      right: 50%;
      bottom: 0;
      transition: all ease 0.3s;
    }
    &::after {
      content: '';
      display: block;
      width: 0rem;
      height: 0.2rem;
      background-color: #28CBEB;
      position: absolute;
      left: 50%;
      bottom: 0;
      transition: all ease 0.3s;
    }
    &.focus {
      &::before, &::after {
        width: 50%;
      }
    }
    .no-select-input {
      width: 100%;
      height: 3.2rem;
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.42);
      outline: none;
      font-size: 1.6rem;
      text-align: left;
      caret-color: #28CBEB;
      color: rgba(0,0,0,.87);
      &:disabled {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.3);
      }
      &.dashed-line {
        border-bottom-style: dashed;
      }
    }
    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 2.4rem;
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      color: rgba(0,0,0,.54);
      position: absolute;
      right: 0;
      top: 48%;
      transform: translateY(-50%);
      transition: all ease 0.3s;
      &.focus {
        color: #28CBEB;
      }
    }
  }
  @media screen and (max-width: 500px) {
    font-size: 1.3rem;
    span {
      font-size: 1.3rem;
    }
    .input-wrap {
      .no-select-input {
        height: 3rem;
        font-size: 1.4rem;
      }
      .material-icons {
        font-size: 2rem;
      }
    }
  }
`

export const BorderInputWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 5.6rem;
  border: 0.1rem solid #dfdfdf;
  border-radius: 0.4rem;
  position: relative;
  transition: all ease 0.3s;
  padding: 0 1.5rem;
  &.focus {
    border: 0.1rem solid #28CBEB;
  }
  span {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.4rem;
    position: absolute;
    left: 1rem;
    top: -0.7rem;
    background-color: #fff;
    transition: all ease 0.3s;
    padding: 0 0.5rem;
    &.focus {
      color: #28CBEB;
    }
    &.disabled {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  button {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 2.4rem;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: rgba(0, 0, 0, 0.54);
    margin-left: 0.5rem;
    transition: all ease 0.3s;
    &.focus {
      color: #28CBEB;
    }
    &.disabled {
      color: rgba(0, 0, 0, 0.3);
      cursor: auto;
    }
  }
  @media screen and (max-width: 500px) {
    height: 5rem;
    button {
      font-size: 2rem;
    }
  }
`
