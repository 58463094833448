import styled from "styled-components";

export const PopupWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 9;
  .contents {
    > a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .info-wrap {
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    label {
      display: flex;
      align-items: center;
      input {
        width: 1.6rem;
        height: 1.6rem;
        border: 0.1rem solid #fff;
        border-radius: 0.2rem;
        position: relative;
        cursor: pointer;
        &:checked {
          background-color: #28CBEB;
          border: 0;
          &::after {
            content: '';
            display: block;
            width: 1.2rem;
            height: 1rem;
            background: url(/image/check-icon.png) no-repeat center / cover;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      span {
        display: block;
        font-size: 1.4rem;
        font-weight: 400;
        color: #fff;
        margin-left: 1rem;
      }
    }
    button {
      color: #fff;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
`
