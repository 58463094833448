import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'

interface getOrderMyHistoryProps {
  page: number
  size: number
  user_id: number
}

interface OrderPops {
  user_id: number
  total: number
  pay_method: string
  totalCnt: number
  receiver_name: string
  receiver_phone: string
  service_id: string
  order_id: string
  track_id: string
  amount: string
  trxdate: string
  authcd: string
  response_code: string
  options: {
    id: number
    cnt: number
    original_price: number
    tax: number
    parent: number
  } []
}

export const OrderAction = () => {

  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)

  const order = async (params: any) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/order/order`,
      params: params
    }).then(
      response => {
        loader({isLoading: false})
        return response.data
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getOrderMyHistory = async (params: getOrderMyHistoryProps) => {

    loader({isLoading: true})

    return await defaultService.handleService({
      method: 'post',
      endPoint: `/order/myOrderList`,
      params: params
    }).then(
      response => {
        loader({isLoading: false})
        return response.data.payload
      },
      error => {
        loader({isLoading: false})
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    order,
    getOrderMyHistory,
  }
}
