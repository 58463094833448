import React, { useEffect, useState } from 'react'
import { FindUserWrap } from './styles'
import Button from '../../module/button'
import { AuthAction } from '../../store/action'
import { useSetRecoilState } from 'recoil'
import { alertAtom } from '../../store/recoil/alert'

const FindUser = () => {

  // Find Action
  const {findUserId, findUserPassword} = AuthAction()

  // Alert
  const setAlert = useSetRecoilState(alertAtom)

  // Input Focus
  const [inputFocus, setInputFocus] = useState(false)
  const handleFocus = () => {
    setInputFocus(true)
  }
  const handleBlur = () => {
    setInputFocus(false)
  }

  // Type
  const [type, setType] = useState(0)

  // userId
  const [userId, setUserId] = useState('')

  // 본인인증
  const cert = (type: number) => {
    setType(type)
    if (type && userId.length === 0) {
      setAlert({
        title: '아이디 확인',
        type: 'alert',
        message: '아이디를 입력해주세요.',
        isShow: true
      })
    } else {
      window.open(`${process.env.REACT_APP_BASE_URL}cert/make_hash`, "_blank", "width=600,height=400")
    }
  }

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.res_cd === '0000') {
        (type && userId) ? (
          findUserPassword({
            userId: userId,
            name: event?.data.user_name,
            phone: event?.data.phone_no
          })
          .then((res) => {
            if(res) {
              setAlert({
                title: res.title,
                type: 'alert',
                message: `${res.message}${'\n'}비밀번호: ${res.payload}${'\n'}임시비밀번호를 휴대폰으로 전송해드렸습니다.`,
                isShow: true
              })
            } else {
              setAlert({
                title: '오류',
                type: 'alert',
                message: `본인인증에 실패했습니다.`,
                isShow: true
              })
            }
          })
        ) : (
          findUserId({
            name: event?.data.user_name,
            phone: event?.data.phone_no
          })
          .then((res) => {
            setAlert({
              title: res.title,
              type: 'alert',
              message: `${res.message}${'\n'}아이디: ${res.payload}`,
              isShow: true
            })
          })
        )
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [type, userId])

  return (
    <FindUserWrap>
      <div className="box">
        <div className="title-wrap">
          <h2>아이디 찾기</h2>
        </div>
        <div className='btn-wrap'>
          <Button
            text='본인인증'
            backgroundColor='#28CBEB'
            borderColor='#28CBEB'
            textColor='#fff'
            icon='/image/find-user-icon.png'
            handleClick={() => cert(0)}
          />
        </div>
        <div className="title-wrap">
          <h2>비밀번호 찾기</h2>
        </div>
        <div className='btn-wrap'>
          <label
            htmlFor='find-id'
            className={inputFocus ? 'id-wrap active' : userId ? 'id-wrap on' : 'id-wrap'}
          >
            <span>아이디</span>
            <input
              type="text"
              id="find-id"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </label>
          <Button
            text='본인인증'
            backgroundColor='#28CBEB'
            borderColor='#28CBEB'
            textColor='#fff'
            icon='/image/find-user-icon.png'
            handleClick={() => cert(1)}
          />
        </div>
      </div>
    </FindUserWrap>
  )
}

export default FindUser
