import {atom} from "recoil";

export const orderAtom = atom({
  key: 'orderAtom',
  default : {
    user_id: 0,
    total: 0,
    pay_method: "",
    totalCnt: 0,
    receiver_name: "",
    receiver_phone: "",

    orderName: '',
    options: [
      {
        id: 0,
        cnt: 0,
        original_price: 0,
        tax: 0,
        parent: 0
      }
    ]
  }
})
