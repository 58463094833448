import styled from "styled-components";

export const CheckBoxStyled = styled.input`
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid #999;
  border-radius: 0.2rem;
  position: relative;
  cursor: pointer;
  &:checked {
    background-color: #28CBEB;
    border: 0;
    &::after {
      content: '';
      display: block;
      width: 1.2rem;
      height: 1rem;
      background: url('/image/check-icon.png') no-repeat center / cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 500px) {
    width: 1.6rem;
    height: 1.6rem;
  }
`
